import React from 'react';
import './ControlsOverlay.css'

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { auth, db, logout } from "./firebase";
import { query, collection, getDocs, where, onSnapshot, setDoc, doc } from "firebase/firestore";

class EditorOverlay extends React.Component {
  constructor(props){
    super(props)
    let firstName = (this.props.selectedNode != null) ? this.props.selectedNode.firstName : "";
    let lastName = (this.props.selectedNode != null) ? this.props.selectedNode.lastName : "";
    let label = (this.props.selectedNode != null) ? this.props.selectedNode.label : "";
    this.state = {
      selectedNode: this.props.selectedNode,
      firstName: firstName,
      lastName: lastName,
      label: label,
      children: []
    }
    // this.handleChange = this.handleChange.bind(this)
  }

  async componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.selectedNode !== this.props.selectedNode){

      // Load children
      let children = [];
      const childrenQuery = query(collection(db, "relationships"), where("source", "==", this.props.selectedNode.id));
      const childrenQuerySnapshot = await getDocs(childrenQuery);
      childrenQuerySnapshot.forEach((doc) => {
        console.log(doc.id)
        children.push(doc.data())
        this.setState({children: children})
      });

      this.setState({
        selectedNode: this.props.selectedNode,
        firstName: this.props.selectedNode.firstName,
        lastName: this.props.selectedNode.lastName,
        label: this.props.selectedNode.label,
        // relationships: thi
      })
      console.log(this.props.selectedNode);
      console.log(this.state.children)
    }
  }

  handleChange(field, e){
    let t = {}
    t[field] = e.target.value
    this.setState(t)
  }

  handleBlur(field, e){
    console.log("Changing " + field + " for " + this.state.selectedNode.id + " to " + e.target.value)
    const memberRef = doc(db, 'members', this.state.selectedNode.id);
    let t = {}
    t[field] = e.target.value
    setDoc(memberRef, t, { merge: true });
  }

  render() {
    if(this.state.selectedNode == null){
      return <Box
          id="editor"
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            display: "inline-flex",
            position: "fixed",
            top: "0",
            left: "5px",
            zIndex: "100",
          }}
          noValidate
          autoComplete="off"
        ><i>Select a node to view details</i></Box>
    } else {

      return <Box
          id="editor"
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            display: "inline-flex",
            position: "fixed",
            top: "0",
            left: "5px",
            zIndex: "100",
          }}
          noValidate
          autoComplete="off"
        >
          <Stack>
            <TextField
              required
              id="filled-required"
              label="Display Name"
              value={this.state.label}
              variant="filled"
              onChange={(e) => this.handleChange("label", e)}
              onBlur={(e) => this.handleBlur("label", e)}
            />
            <TextField
              required
              id="filled-required"
              label="First Name"
              value={this.state.firstName}
              variant="filled"
              onChange={(e) => this.handleChange("firstName", e)}
              onBlur={(e) => this.handleBlur("firstName", e)}
            />
            <TextField
              required
              id="filled-required"
              label="Last Name"
              value={this.state.lastName}
              variant="filled"
              onChange={(e) => this.handleChange("lastName", e)}
              onBlur={(e) => this.handleBlur("lastName", e)}

            />
          </Stack>
        </Box>
    }
  }
}

export default EditorOverlay;
