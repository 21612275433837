import DeckGL from '@deck.gl/react';
import {PointCloudLayer} from '@deck.gl/layers';
import {COORDINATE_SYSTEM, OrbitView} from '@deck.gl/core';
import React from 'react';
import { Typography } from '@mui/material';
// var fastSimplexNoise = require("fast-simplex-noise")
import imagesJson from './images-7.json';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ControlsOverlay from './ControlsOverlay.js';
import HelpView from './HelpView.js';
import './App.css';
import {isMobile} from 'react-device-detect';
var convert = require('color-convert');

const imageBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '80%',
  maxHeight: '80%',
  overflow: "auto",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 2,
  zIndex: 100
};

class PhotoExplorer extends React.Component {
  constructor(props){
    super(props)
    let data = imagesJson.data;

    let defaultZoom = (isMobile) ? 0 : 0.5;
    let radiusPixels = (isMobile) ? 8 : 6;
    let r_scale = 4;
    let scale = 3;

    const INITIAL_VIEW_STATE = {
      target: [0, 0, 0],
      rotationOrbit: 0,
      rotationX: 0,
      minZoom: 0,
      maxZoom: 3,
      zoom: defaultZoom
    };
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      // width: 'auto',
      maxWidth: '90%',
      maxHeight: '90%',
      overflow: "auto",
      bgcolor: 'background.paper',
      border: '1px solid #000',
      boxShadow: 24,
      p: 4,
      zIndex: 100
    };
    const helpText = <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Inspiration
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        This visualization is an attempt at designing a meaningful representation of my photography work. At the time of initial development, the flexbox approach I used in the past did not reflect my programming and photography skills in a way I felt was adequate, so I wanted to explore some new representations.
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        The first stage of producing this involved pre-processing my images to generate a dataset of the most dominant colors by utilizing the scikit-learn implementation of k-means clustering. From here, I was able to map the color values back to the images and single out the most dominant computed value and convert it to both RGB and HSL color formats.
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        The visualization itself was developed using deck.gl, a WebGL data visualization framework which specializes in large datasets. While probably overkill for this project, deck.gl has the capabilities to handle massive datasets and I was able to build out a base template for a few other projects I have in mind.
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Each photograph is represented by a single dot on the graph and when clicked on, it will display the corresponding photo. As part of the development process, I realized the graphing of RGB (Red-Green-Blue) provided a representation that essentially only showed the "lightness" of my work. The current mapping method utilizes HSL (Hue-Saturation-Luminance) which I believe forms better patterns in the visualization.
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        The coordinate structure on this graph is focused around the vertical axis (vertical on initial page load) and can be thought of as a column. Hue is displayed radially as the color scale is wrapped around this axis. The distance from the axis corresponds to the Saturation where a more saturated dominate color would cause a dot to be graphed farther from the center. The Luminance value is the "height" or how high along this vertical axis the dot is graphed.
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        This leads to a few observations of my work, most notably the vertical line at the center of the graph. As one may expect, all of my monochrome photography naturally falls into this line due to the lack of saturation in the images. However when rotating the graph to look at it from a top down view, two new and rather dispersed groupings appear in the blue and green-brown ranges of the hue scale. Observing samples of this photos provides a pattern in my photographs which directly coincides with my main source of photos, backpacking. In the end, I feel this provides a more aesthetically pleasing visualization which better represents the intersection of my hobbies.
      </Typography>
    </Box>;

    const p_layer = new PointCloudLayer({
        id: 'picture-point-layer',
        data,
        pickable: true,
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        radiusPixels: radiusPixels,
        getPosition: (d) => {
          let hsl = convert.rgb.hsl(d.color[0], d.color[1], d.color[2])
          let x = r_scale * hsl[1] * Math.cos(hsl[0] * Math.PI / 180)
          let y = r_scale * hsl[1] * Math.sin(hsl[0] * Math.PI / 180)
          return [x, y, (hsl[2] - 50) * scale]
        },
        getColor: d => d.color,
        onClick: ({object}) => this.imageClick(object)
      });

    this.state = {
      data: data,
      viewState: INITIAL_VIEW_STATE,
      layers: [p_layer],
      image: null,
      imageOpen: false,
      helpText: helpText
    };

    this.genericClick = this.genericClick.bind(this)
  }

  getTooltip(object){
    return object && object.name;
  }

  genericClick(object){
    if(object != null && object.layer == null){
      this.setState({imageOpen: false});
    }
  }

  imageClick(object){
    this.setState({image: object, imageOpen: true});
  }

  render() {
    return <div>
        <Modal
          open={this.state.imageOpen}
          onClose={this.genericClick}
        >
          <Box sx={imageBoxStyle}>
            {this.state.imageOpen ? <img className="image" src={"/images/" + this.state.image.name} alt={this.state.image.name} /> : <p>Click On A Dot</p>}
          </Box>
        </Modal>
        <ControlsOverlay controls={"3D"} />
        <HelpView content={this.state.helpText}/>
        <div className="deck">
          <DeckGL
            views={new OrbitView()}
            viewState={this.state.viewState}
            controller={true}
            onViewStateChange={v => this.setState({viewState: v.viewState, rotationX: v.rotationX})}
            layers={this.state.layers}
            getTooltip={({object}) => this.getTooltip(object)}
            onClick={(object) => this.genericClick(object)}
          />
        </div>
      </div>;
  }
}

export default PhotoExplorer;
