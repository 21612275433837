import React from 'react';
// import './NavigationView.css'

import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CalculateIcon from '@mui/icons-material/Calculate';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { SportsBar } from '@mui/icons-material';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import FunctionsIcon from '@mui/icons-material/Functions';
import HomeIcon from '@mui/icons-material/Home';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import SsidChartIcon from '@mui/icons-material/SsidChart';

import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import linksData from "./data/links.json";


class NavigationView extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      navOpen: false,
      redirect: false
    }
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.getItemIcon = this.getItemIcon.bind(this);
  }

  getItemIcon(type){
    switch(type){
      case "AcUnit":
        return <AcUnitIcon />
      case "Beer":
        return <SportsBar />
      case "Calculate":
        return <CalculateIcon />
      case "ContactPage":
        return <ContactPageIcon />
      case "FamilyRestroom":
        return <FamilyRestroomIcon />
      case "Functions":
        return <FunctionsIcon />
      case "Home":
        return <HomeIcon />
      case "LinkedIn":
        return <LinkedInIcon />
      case "PhotoCamera":
        return <PhotoCameraIcon />;
      case "SsidChart":
        return <SsidChartIcon />
      case "default":
      default:
        return <ArrowRightIcon />
    }
  }

  getItemList(item, padding=2){
    return <ListItemButton key={item.title} button component="a" href={item.path} sx={{ pl: padding }}>
      <ListItemIcon>
        {this.getItemIcon(item.buttonType)}
      </ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItemButton>
  }


  toggleDrawer(event){
    this.setState({navOpen: !this.state.navOpen});
  }

  render() {
      return <div id="nav">
        <React.Fragment>
          <IconButton onClick={this.toggleDrawer} ><MenuIcon fontSize="large"/></IconButton>
          <Drawer
            anchor={"right"}
            open={this.state.navOpen}
            onClose={this.toggleDrawer}
          >
            {this.state.navOpen
              ?<IconButton onClick={this.toggleDrawer} className="drawerToggle">
                  <ListItemText primary="Close" /><ChevronRightIcon />
              </IconButton>
              :<IconButton onClick={this.toggleDrawer} className="drawerToggle">
                  <ChevronLeftIcon />
              </IconButton>
            }
            <List>
              {linksData.links.map((item, index) => {
                if(item.type != null && item.type === "sublist"){
                  return (<List key={item.title} >
                    <ListItemButton key={item.title} button component="a" href={item.path}>
                      <ListItemIcon>
                        {this.getItemIcon(item.buttonType)}
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                    {item.list.map((item, index) => (
                      this.getItemList(item, 4)
                    ))}
                  </List>)
                } else {
                  return (this.getItemList(item));
                }
              })}
            </List>
          </Drawer>
        </React.Fragment>
      </div>
  }
}

export default NavigationView;
