import DeckGL from '@deck.gl/react';
import {PointCloudLayer, LineLayer, PathLayer} from '@deck.gl/layers';
import {COORDINATE_SYSTEM, OrbitView, LinearInterpolator} from '@deck.gl/core';
import React, {useState, useEffect} from 'react';
var fastSimplexNoise = require("fast-simplex-noise")


const R = 30;
const noise = fastSimplexNoise.makeNoise3D();
const colors = [{"name":"Cadet Blue","hex":"51a3a3","rgb":[81,163,163],"cmyk":[50,0,0,36],"hsb":[180,50,64],"hsl":[180,34,48],"lab":[62,-25,-8]},{"name":"Eggplant","hex":"75485e","rgb":[117,72,94],"cmyk":[0,38,20,54],"hsb":[331,38,46],"hsl":[331,24,37],"lab":[36,23,-5]},{"name":"Persian Orange","hex":"cb904d","rgb":[203,144,77],"cmyk":[0,29,62,20],"hsb":[32,62,80],"hsl":[32,55,55],"lab":[64,15,44]},{"name":"Arylide Yellow","hex":"dfcc74","rgb":[223,204,116],"cmyk":[0,9,48,13],"hsb":[49,48,87],"hsl":[49,63,66],"lab":[82,-5,46]},{"name":"Yellow Green Crayola","hex":"c3e991","rgb":[195,233,145],"cmyk":[16,0,38,9],"hsb":[86,38,91],"hsl":[86,67,74],"lab":[88,-27,39]}];
// function generateSphere(){
//   for (let a = 0; a < 360; a += 1){
//     for (let b = 0; b < 360; b += 1){
//         let x = Math.ceil(R * Math.cos(a * 180 / Math.PI) * Math.sin(b * 180 / Math.PI));
//         let y = Math.ceil(R * Math.sin(a * 180 / Math.PI) * Math.sin(b * 180 / Math.PI));
//         let z = Math.ceil(R * Math.cos(b * 180 / Math.PI));
//         data.push({position: [x, y, z]})
//     }
//   }
// }

// function generateSpiralSphere(){
//   for (let a = 0; a < 360; a += 1){
//     let alpha = a * 180 / Math.PI;
//     let theta = (90 - a) * 180 / Math.PI;
//     let x = Math.ceil(R * Math.cos() * Math.sin(alpha * 180 / Math.PI));
//     let y = Math.ceil(R * Math.sin(theta * 180 / Math.PI) * Math.sin(alpha * 180 / Math.PI));
//     let z = Math.ceil(R * Math.cos(alpha * 180 / Math.PI));
//     data.push({position: [x, y, z]})
//   }
// }
class Walkers extends React.Component {

  constructor(props){
    super(props)
    var data = [];
    for(let i = 0; i< 50; i++){
      data.push(this.generateWalkers());
    }
    for(let i = 0; i< 100; i++){
      data.forEach((item, i) => {
        this.moveWalkers(item, i);
      });

    }
    console.log(data)
    const INITIAL_VIEW_STATE = {
      target: [0, 0, 0],
      rotationOrbit: 0,
      minZoom: 0,
      maxZoom: 20,
      zoom: 1
    };
    const path = new PathLayer({
      id: 'path-layer',
      data,
      pickable: true,
      widthScale: 1,
      widthMinPixels: 2,
      widthMaxPixels: 5,
      getPath: d => d.path,
      getColor: d => d.color,
      getWidth: d => 2
    });

    this.state = {
      data: data,
      viewState: INITIAL_VIEW_STATE,
      layers: [path]
    };
  }

  generateWalkers(){
    let w = {};
    w.x = 0;
    w.y = 0;
    w.z = 0;
    w.path = [[w.x, w.y, w.z]];
    // w.velocityX = Math.random()*0.5-0.25;
    // w.velocityY = Math.random()*0.5-0.25;
    // w.velocityZ = Math.random()*0.5-0.25;
    w.velocityX = Math.random();
    w.velocityY = Math.random();
    w.velocityZ = Math.random();
    w.color = colors[Math.floor(Math.random()*5)].rgb;
    // w.color = [25, 75, 175];
    return w;
  }

  moveWalkers(w){
    w.velocityX += noise(w.x, w.y, w.z);
    w.velocityY += noise(w.y, w.x, w.z);
    w.velocityZ += noise(w.z, w.y, w.x);
    w.x += w.velocityX;
    w.y += w.velocityY;
    w.z += w.velocityZ;
    w.path.push([w.x, w.y, w.z])
  }

  // const p_layer = new PointCloudLayer({
  //   id: 'point-cloud-layer',
  //   data,
  //   pickable: false,
  //   coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
  //   radiusPixels: 2,
  //   getPosition: d => d.position,
  //   // getNormal: d => [0, 1, 0],
  //   getColor: d => [25, 25, 25]
  // });

  // const l_layer = new LineLayer({
  //     id: 'flight-paths',
  //     data: data,
  //     opacity: 0.8,
  //     getSourcePosition: d => d.start,
  //     getTargetPosition: d => d.end,
  //     getColor: [25, 25, 25],
  //     getWidth: 5,
  //   })



  render() {
    return <DeckGL
      views={new OrbitView()}
      viewState={this.state.viewState}
      controller={true}
      onViewStateChange={v => this.setState({viewState: v.viewState})}
      layers={this.state.layers}
      // getTooltip={({object}) => object && object.position.join(', ')}
    />;
  }
}

export default Walkers;
