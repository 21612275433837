import React from 'react';
// import './NavigationView.css'
import Modal from '@mui/material/Modal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import { Box, Typography } from '@mui/material';


class HelpView extends React.Component {
  constructor(props){
    super(props)
    console.log(props)
    this.state = {
      helpOpen: false
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(event){
    this.setState({helpOpen: !this.state.helpOpen});
  }

  // getHelp(section){
  //   const style = {
  //     position: 'absolute',
  //     top: '50%',
  //     left: '50%',
  //     transform: 'translate(-50%, -50%)',
  //     // width: 'auto',
  //     maxWidth: '90%',
  //     maxHeight: '90%',
  //     overflow: "auto",
  //     bgcolor: 'background.paper',
  //     border: '1px solid #000',
  //     boxShadow: 24,
  //     p: 4,
  //     zIndex: 100
  //   };
  //   if(section === "beermap"){
  //     return 
  //       <Box sx={style}>
  //         <Typography id="modal-modal-title" variant="h6" component="h2">
  //           What am I looking at?
  //         </Typography>
  //         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //           This visualization is an attempt at visualizing similarities in beer styles according to the BJCP.
  //         </Typography>
  //         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //           Data was gathered from the BJCP website in a JSON format and preprocessed with Python. At a high level, a dimensionality reduction algorithm was used to process this data into a 3-dimensional format. These created values were then mapped in this diagram, with the color of the text being related to the SRM value (read as color) of the beer.
  //         </Typography>
  //         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //           I haven't spent much time trying to infer relationships between beer styles, but based on the algorithms used, distance between styles indicate some level of relationship. This is easy to see in some places such as the different types of stouts, some of the Belgian beers, etc. but hard to determine what the actual relationships are since this is an expression of higher dimensions.
  //         </Typography>
  //         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //           To go a little deeper into what is happening under the hood, I utilized the Python library Pandas to create DataFrames of the "beer" data that I could manipulate more easily. The numerical data was relatively easy to work with, but the text was more complicated since many algorithms only handle numerical data. I chose a few attributes, preprocessed the text by normalizing it in lowercase and removing punctuation, tokenized it, and then ran TF-IDF (Term Frequency-Inverse Document Frequency) in order to evaluate the importance of words throughout the descriptions, characteristics and other pieces of information. After joining this data to the DataFrame containing numerical values, I used the dimensionality reduction algorithm PCA (Principal Component Analysis) to reduce to 3 dimensions, and added those data values back into the initial JSON data set. This data set was then loaded and manipulated to be viewed on this page, with the PCA outputs scaled (to expand the closer) and mapped to X, Y, and Z coordinated.
  //         </Typography>
  //       </Box> 
  //   } else if (section === "photomap"){    
  //     return 
  //       <Box sx={style}>
  //         <Typography id="modal-modal-title" variant="h6" component="h2">
  //           Inspiration
  //         </Typography>
  //         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //           This visualization is an attempt at designing a meaningful representation of my photography work. At the time of initial development, the flexbox approach I used in the past did not reflect my programming and photography skills in a way I felt was adequate, so I wanted to explore some new representations.
  //         </Typography>
  //         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //           The first stage of producing this involved pre-processing my images to generate a dataset of the most dominant colors by utilizing the scikit-learn implementation of k-means clustering. From here, I was able to map the color values back to the images and single out the most dominant computed value and convert it to both RGB and HSL color formats.
  //         </Typography>
  //         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //           The visualization itself was developed using deck.gl, a WebGL data visualization framework which specializes in large datasets. While probably overkill for this project, deck.gl has the capabilities to handle massive datasets and I was able to build out a base template for a few other projects I have in mind.
  //         </Typography>
  //         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //           Each photograph is represented by a single dot on the graph and when clicked on, it will display the corresponding photo. As part of the development process, I realized the graphing of RGB (Red-Green-Blue) provided a representation that essentially only showed the "lightness" of my work. The current mapping method utilizes HSL (Hue-Saturation-Luminance) which I believe forms better patterns in the visualization.
  //         </Typography>
  //         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //           The coordinate structure on this graph is focused around the vertical axis (vertical on initial page load) and can be thought of as a column. Hue is displayed radially as the color scale is wrapped around this axis. The distance from the axis corresponds to the Saturation where a more saturated dominate color would cause a dot to be graphed farther from the center. The Luminance value is the "height" or how high along this vertical axis the dot is graphed.
  //         </Typography>
  //         <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //           This leads to a few observations of my work, most notably the vertical line at the center of the graph. As one may expect, all of my monochrome photography naturally falls into this line due to the lack of saturation in the images. However when rotating the graph to look at it from a top down view, two new and rather dispersed groupings appear in the blue and green-brown ranges of the hue scale. Observing samples of this photos provides a pattern in my photographs which directly coincides with my main source of photos, backpacking. In the end, I feel this provides a more aesthetically pleasing visualization which better represents the intersection of my hobbies.
  //         </Typography>
  //       </Box>
  //   }
  // }



  render() {
      return <div id="help">
        <React.Fragment>
          <IconButton onClick={this.toggleModal} ><HelpOutlineIcon fontSize="large"/></IconButton>
          <Modal
            open={this.state.helpOpen}
            onClose={this.toggleModal}
          >
            {this.props.content ? this.props.content : <div></div>}
          </Modal>
        </React.Fragment>
      </div>
  }
}

export default HelpView;
