import React from 'react';
// import './FamilyTreeView.css'
import * as d3 from "d3";
import { ForceGraph2D } from "react-force-graph";
// import data from "./data/family.json";

import { Navigate } from "react-router-dom";
import { auth, db, logout } from "./firebase";
import { getAuth } from "firebase/auth"
import { query, collection, getDocs, where, onSnapshot } from "firebase/firestore";
import ControlsOverlay from "./ControlsOverlay.js"
import EditorOverlay from "./EditorOverlay.js"
import jwt_decode from "jwt-decode";

class FamilyTreeView extends React.Component {
  constructor(props){
    super(props)
    // const [user, loading, error] = firebase.auth(auth);
    this.state = {
      name: "",
      user: props.user,
      navigate: null,
      redirect: false,
      memberRef: null,
      relationRef: null,
      nodes: [],
      links: [],
      selectedNode: null,
      memberListener: null,
      relationListener: null,
      linkDistanceMultiplier: 1,
      nodeChargeMultiplier: 1,
      nodeStrengthMultiplier: 1
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.image !== this.props.image){
      console.log("Props updated")
    }
  }

  async componentDidMount(){
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          this.setState({roles: jwt_decode(token).roles})
        })
        this.setState({user: user})
      } else {
        this.setState({navigate: "/login"})
      }
    })

    // Load initial members
    // let nodes = this.state.nodes
    // const memberQuerySnapshot = await getDocs(collection(db, "members"));
    // memberQuerySnapshot.forEach((doc) => {
    //   let d = doc.data()
    //   d.id = doc.id
    //   nodes.push(d)
    //   this.setState({nodes: nodes})
    // });

    const memberListener = onSnapshot(query(collection(db, "members")), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
            let d = change.doc.data()
            d.id = change.doc.id
            this.setState({ nodes: [...this.state.nodes, d] })
        }
        if (change.type === "modified") {
            console.log("Modified member: ", change.doc.data());
        }
        if (change.type === "removed") {
            let nodes = this.state.nodes.filter(member => member.id != change.doc.id);
            this.setState({nodes: nodes})
            console.log("Removed member: ", change.doc.data());
        }
      });
    });

    // Load initial relationships
    // let links = this.state.links
    // const relationsQuerySnapshot = await getDocs(collection(db, "relationships"));
    // relationsQuerySnapshot.forEach((doc) => {
    //   links.push(doc.data())
    //   this.setState({links: links})
    // });

    const relationListener = onSnapshot(query(collection(db, "relationships")), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
            this.setState({ links: [...this.state.links, change.doc.data()] })
        }
        if (change.type === "modified") {
            console.log("Modified relationship: ", change.doc.data());
        }
        if (change.type === "removed") {
            console.log("Removed relationship: ", change.doc.data());
        }
      });
    });


    if(this.state.user != null){
      // this.linkDistanceUpdate();
      // this.linkChargeUpdate();
    }
  }

  // linkDistanceUpdate(){
  //   this.mount.d3Force("link", d3.forceLink().distance((d) => {
  //     if(d.type === "Marriage"){
  //       return 10 * this.state.linkDistanceMultiplier;
  //     } else {
  //       return 150 * this.state.linkDistanceMultiplier;
  //     }
  //   }))
  // }

  // linkChargeUpdate(){
  //   this.mount.d3Force("charge", d3.forceManyBody().theta(0.5 * this.state.nodeChargeMultiplier).strength(-1000 * this.state.nodeStrengthMultiplier));
  //   this.mount.numDimensions(3);
  // }

  linkTypeColorPicker(link){
    if(link.type === "Marriage"){
      return 'rgba(0,0,0,0.8)';
    } else if(link.type === "Mother"){
      return 'rgba(255,192,203,1)';
    } else if(link.type === "Father"){
      return 'rgba(0,0,190,1)';
    } else {
      return 'rgba(255,0,0,1)';
    }
  }

  linkWidthPicker(link){
    if(link.type === "Marriage"){
      return 4;
    } else {
      return 2;
    }
  }

  nodeObjectGenerator(node, ctx, globalScale){
    const label = node.label;
    const fontSize = 12/globalScale;
    ctx.font = `${fontSize}px Sans-Serif`;
    const textWidth = ctx.measureText(label).width;
    const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2); // some padding

    ctx.fillStyle = 'rgba(128, 128, 128, 0.8)';
    ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
    ctx.fillText(label, node.x, node.y);

    node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
  }

  nodeTypeColorPicker(node){
    return 'rgba(0,0,0,1)';
  }

  handleNodeClick(node){
    this.setState({selectedNode: node})
  }

  render() {
    if(this.state.navigate != null){
        return <Navigate to={this.state.navigate} replace={true} state={{redirectURL: "/projects/family"}} />
    } else if(this.state.user != null && this.state.nodes.length != 0 && this.state.links.length != 0) {
      return <div id="FamilyTree">
        <ControlsOverlay controls={"2D"} />
        {this.state.roles.includes("editor") ? <EditorOverlay selectedNode={this.state.selectedNode} nodes={this.state.nodes}/> : <div></div>}
        <div id="graph"></div>
        <ForceGraph2D
          ref={ref => (this.mount = ref)}
          graphData={{
            nodes: this.state.nodes,
            links: this.state.links
          }}
          nodeColor={node => this.nodeTypeColorPicker(node)}
          linkColor={link => this.linkTypeColorPicker(link)}
          linkWidth={link => this.linkWidthPicker(link)}
          nodeCanvasObject={(node, ctx, globalScale) => {this.nodeObjectGenerator(node, ctx, globalScale)}}
          nodePointerAreaPaint={(node, color, ctx) => {
            ctx.fillStyle = color;
            const bckgDimensions = node.__bckgDimensions;
            bckgDimensions && ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);
          }}
          onNodeClick={node => this.handleNodeClick(node)}
        />
      </div>
    } else {
      return <div></div>
    }
  }
}

export default FamilyTreeView;
