import DeckGL from '@deck.gl/react';
import {COORDINATE_SYSTEM, OrbitView} from '@deck.gl/core';
import React from 'react';
import beerJSONFull from './data/structured.json';
import beerJSONReduced from './data/structured-reduced.json';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ControlsOverlay from './ControlsOverlay.js';
import { Typography } from '@mui/material';
import './App.css';
import {isMobile} from 'react-device-detect';
import { TextLayer } from 'deck.gl';
import HelpView from './HelpView.js';
// import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';



const infoStyle = {
  position: 'absolute',
  top: '50%',
  left: '10%',
  transform: 'translate(0px, -50%)',
  maxWidth: '40%',
  maxHeight: '80%',
  overflow: "auto",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 2,
  zIndex: 100
};

class BeerView extends React.Component {
 

  render() {
    let beer = this.props.beer;
    return <div>
        <Typography variant="h1">{beer.name}</Typography>
        <Typography variant="h6">{"Style: " + beer.number + " - " + beer.category}</Typography>
        { beer.srmmin != null ? <div><Typography variant="body1">{"SRM Range: " + beer.srmmin + " - " + beer.srmmax}</Typography></div> : <div><Typography variant="body1">Note: SRM values not present</Typography></div>}
        { beer.ibumin != null ? <div><Typography variant="body1">{"IBU Range: " + beer.ibumin + " - " + beer.ibumax}</Typography></div> : <div><Typography variant="body1">Note: IBU values not present</Typography></div>}
        { beer.abvmin != null ? <div><Typography variant="body1">{"ABV Range: " + beer.abvmin + " - " + beer.abvmax}</Typography></div> : <div><Typography variant="body1">Note: ABV values not present</Typography></div>}
        { beer.ogmin != null ? <div><Typography variant="body1">{"OG Range: " + beer.ogmin + " - " + beer.ogmax}</Typography></div> : <div><Typography variant="body1">Note: OG values not present</Typography></div>}
        { beer.fgmin != null ? <div><Typography variant="body1">{"FG Range: " + beer.fgmin + " - " + beer.fgmax}</Typography></div> : <div><Typography variant="body1">Note: FG values not present</Typography></div>}
        { beer.currentlydefinedtypes != null ? <div><Typography variant="body1">{"Currently Defined Types: " + beer.currentlydefinedtypes}</Typography></div> : <div></div>}
        { beer.strengthclassifications != null ? <div><Typography variant="body1">{"Strength Classifications: " + beer.strengthclassifications}</Typography></div> : <div></div>}
        <Typography variant="h4">Impression</Typography>
        <Typography variant="body1">{beer.overallimpression}</Typography>
        <Typography variant="h4">Aroma</Typography>
        <Typography variant="body1">{beer.aroma}</Typography>
        <Typography variant="h4">Appearance</Typography>
        <Typography variant="body1">{beer.appearance}</Typography>
        <Typography variant="h4">Flavor</Typography>
        <Typography variant="body1">{beer.flavor}</Typography>
        <Typography variant="h4">Mouthfeel</Typography>
        <Typography variant="body1">{beer.mouthfeel}</Typography>
        <Typography variant="h4">Characteristic Ingredients</Typography>
        <Typography variant="body1">{beer.characteristicingredients}</Typography>
        <Typography variant="h4">History</Typography>
        <Typography variant="body1">{beer.history}</Typography>
        <Typography variant="h4">Comments</Typography>
        <Typography variant="body1">{beer.comments}</Typography>
        { beer.notes != null ? <div><Typography variant="h4">Notes</Typography><Typography variant="body1">{beer.notes}</Typography></div> : <div></div>}
        <Typography variant="h4">Style Comparison</Typography>
        <Typography variant="body1">{beer.stylecomparison}</Typography>
        <Typography variant="h4">Commercial Examples</Typography>
        <Typography variant="body1">{beer.commercialexamples}</Typography>
        { beer.notes != null ? <div><Typography variant="h4">BJCP Entry Instructions</Typography><Typography variant="body1">{beer.entryinstructions}</Typography></div> : <div></div>}
        
      </div>
  }
}

class BeerExplorer extends React.Component {
  constructor(props){
    super(props)

    let defaultZoom = (isMobile) ? 0 : 0.5;


    let data = beerJSONFull.data;
    const INITIAL_VIEW_STATE = {
      target: [0, 0, 0],
      rotationOrbit: 0,
      rotationX: 0,
      minZoom: 0,
      maxZoom: 3,
      zoom: defaultZoom
    };
    
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      // width: 'auto',
      maxWidth: '90%',
      maxHeight: '90%',
      overflow: "auto",
      bgcolor: 'background.paper',
      border: '1px solid #000',
      boxShadow: 24,
      p: 4,
      zIndex: 100
    };
    
    const helpText = <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        What am I looking at?
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        This visualization is an attempt at visualizing similarities in beer styles according to the BJCP.
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Data was gathered from the BJCP website in a JSON format and preprocessed with Python. At a high level, a dimensionality reduction algorithm was used to process this data into a 3-dimensional format. These created values were then mapped in this diagram, with the color of the text being related to the SRM value (read as color) of the beer.
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        I haven't spent much time trying to infer relationships between beer styles, but based on the algorithms used, distance between styles indicate some level of relationship. This is easy to see in some places such as the different types of stouts, some of the Belgian beers, etc. but hard to determine what the actual relationships are since this is an expression of higher dimensions.
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        To go a little deeper into what is happening under the hood, I utilized the Python library Pandas to create DataFrames of the "beer" data that I could manipulate more easily. The numerical data was relatively easy to work with, but the text was more complicated since many algorithms only handle numerical data. I chose a few attributes, preprocessed the text by normalizing it in lowercase and removing punctuation, tokenized it, and then ran TF-IDF (Term Frequency-Inverse Document Frequency) in order to evaluate the importance of words throughout the descriptions, characteristics and other pieces of information. After joining this data to the DataFrame containing numerical values, I used the dimensionality reduction algorithm PCA (Principal Component Analysis) to reduce to 3 dimensions, and added those data values back into the initial JSON data set. This data set was then loaded and manipulated to be viewed on this page, with the PCA outputs scaled (to expand the closer) and mapped to X, Y, and Z coordinates.
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Note: There is a cluster of beer styles in the middle of the map that do not have ABV, SRM, IBU, OG, FG values associated with them according to the BJCP, such as Fruit Beer. This skews their relation to the rest of the data set and in the ABV view, I've colored them all red.
      </Typography>
    </Box>
    

    this.state = {
      data: data,
      viewState: INITIAL_VIEW_STATE,
      layers: [],
      beer: null,
      imageOpen: false,
      helpText: helpText,
      coloring: "srm",
      dataset: "full"
    };

    this.genericClick = this.genericClick.bind(this)
    this.setColoring = this.setColoring.bind(this)
    this.setDataset = this.setDataset.bind(this)
  }

  componentDidMount(){
    this.graphRender();
  }


  getTooltip(object){
    return object && object.name;
  }

  genericClick(object){
    if(object != null && object.layer == null){
      this.setState({imageOpen: false});
    }
  }

  graphRender(){
    console.log(this.state)
    let scale = 100;
    const p_layer = new TextLayer({
      id: 'picture-point-layer',
      data: this.state.data,
      pickable: true,
      coordinateSystem: COORDINATE_SYSTEM.DEFAULT,
      // pointSize: radiusPixels,
      getSize: 10,
      getText: (d) => d.name,
      getPosition: (d) => {
        if(d.srmmin == null){
          let x = d.PC1 * scale * 10;
          let y = d.PC2 * scale * 10;
          let z = d.PC3 * scale * 10;
          return [x, y, z]
        }
        let x = d.PC1 * scale;
        let y = d.PC2 * scale;
        let z = d.PC3 * scale;
        return [x, y, z]
      },
      updateTriggers: {
       getColor: [this.state.coloring]
      },
      getColor: (d) => {
        if(this.state.coloring === "srm"){
          let srm = d.avg_srm;
          let darken = 50;
          if(d.srmmin == null){
            return [20,20,20,128]
          } else if(srm <= 10){
            return [255 - darken, 255 - darken - 19 * srm, 255 - darken - 41 * srm, 255]
          } else if(srm <= 20) {
            return [255 - darken - 17 * (srm - 10), 156 - darken - 10 * (srm - 10), 0, 255]
          } else {
            return [100 - darken - 5 * (srm - 20), 56 - darken - 2 * (srm - 20), 0, 255]
          }
        } else if (this.state.coloring.startsWith("kmeans")){
          const colors = [[31, 119, 180],
          [255, 127, 14],
          [44, 160, 44],
          [214, 39, 40],
          [148, 103, 189],
          [140, 86, 75],
          [227, 119, 194],
          [127, 127, 127],
          [188, 189, 34],
          [23, 190, 207]];
          switch (this.state.coloring){
          case "kmeans5":
            return colors[d.Cluster5];
          case "kmeans7":
            return colors[d.Cluster7];
          case "kmeans10":
          default:
            return colors[d.Cluster10];
          }
        } else if(this.state.coloring = "abv"){
          if(d.srmmin == null){
            return [255,0,0,255];
          }
          let avg = (d.abvmin + d.abvmax) / 2;
          let norm = avg / 15
          let color = 200 * (1 - norm);
          return [color, color, color, 255]
        }
      },
      onClick: ({object}) => this.beerClick(object)
    });

    this.setState({
      layers: [p_layer]
    })
  }

  beerClick(object){
    this.setState({beer: object, imageOpen: true});
  }

  // setColoring(e, alignment){
  //   this.setState({coloring: alignment}, this.graphRender);
  // }
  setColoring(e){
    this.setState({coloring: e.target.value}, this.graphRender);
  }

  setDataset(e){
    let newData;
    switch (e.target.value) {
      case "reduced":
        newData = beerJSONReduced;
        break;
      case "full":
      default:
        newData = beerJSONFull;
    }
    console.log("Changing data to " + e.target.value)
    console.log(newData)
    this.setState({data: newData.data, dataset: e.target.value}, this.graphRender);
  }


  render() {
    return <div>
        <Modal
          open={this.state.imageOpen}
          onClose={this.genericClick}
        >
          <Box sx={infoStyle}>
            {this.state.imageOpen ? <BeerView beer={this.state.beer} /> : <p>Click On A Dot</p>}
          </Box>
        </Modal>
        <ControlsOverlay controls={"3D"} />
        <HelpView content={this.state.helpText} />
        <div id="switches">
          <FormControl autowidth>
            <InputLabel id="coloring-select-label" className='select-label'>Color</InputLabel>
            <Select
              labelId="coloring-select-label"
              id="color-select"
              value={this.state.coloring}
              label="Coloring"
              exclusive
              autowidth
              onChange={(e, v) => {this.setColoring(e, v)}}
            >
              <MenuItem value={"srm"}>SRM</MenuItem>
              <MenuItem value={"abv"}>ABV</MenuItem>
              <MenuItem value={"kmeans5"}>K-Means (5)</MenuItem>
              <MenuItem value={"kmeans7"}>K-Means (7)</MenuItem>
              <MenuItem value={"kmeans10"}>K-Means (10)</MenuItem>
            </Select>
          </FormControl>
          <FormControl autowidth>
            <InputLabel id="dataset-select-label" className='select-label'>Dataset</InputLabel>
            <Select
              labelId="dataset-select-label"
              id="color-select"
              value={this.state.dataset}
              label="Dataset"
              exclusive
              autowidth
              onChange={(e) => {this.setDataset(e)}}
            >
              <MenuItem value={"full"}>Full</MenuItem>
              <MenuItem value={"reduced"}>Reduced</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="deck">
          <DeckGL
            views={new OrbitView()}
            viewState={this.state.viewState}
            controller={true}
            onViewStateChange={v => this.setState({viewState: v.viewState, rotationX: v.rotationX})}
            layers={this.state.layers}
            getTooltip={({object}) => this.getTooltip(object)}
            onClick={(object) => this.genericClick(object)}
          />
        </div>
      </div>;
  }
}

export default BeerExplorer;
