import React from 'react';
import imagesJson from './images-7.json';
import './ImageListView.css'

class ImageListView extends React.Component {
  componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
      var lazyloadImages = document.querySelectorAll("img.lazy");
      var scrollTop = window.pageYOffset;
      lazyloadImages.forEach(function(img) {
          if(img.offsetTop < (window.innerHeight + scrollTop)) {
            img.src = img.dataset.src;
            img.classList.remove('lazy');
          }
      });
      if(lazyloadImages.length == 0) {
        window.removeEventListener('scroll', this.handleScroll);
      }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.image != this.props.image){
      console.log("Props updated")
    }
  }

  render() {
      const listItems = imagesJson.data.map((image, index) =>
        <li key={index} className="imageList">{(index < 10) ? <img className="imageList" src={"/images/low/" + image.name} /> : <img className="imageList lazy" data-src={"/images/low/" + image.name} />}</li>
      )
      return <div id="listBox" className="imageList">
          <ul className="imageList">{listItems}</ul>
        </div>;
  }
}

export default ImageListView;
