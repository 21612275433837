import React from 'react';
import './ThreeView.css'
import * as THREE from "three";

class ThreeView extends React.Component {
  constructor(props){
    super(props)

  }


  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.image != this.props.image){
      console.log("Props updated")
    }
  }

  componentDidMount(){
    // const id = this.props.match.params.id;
    // if(id == "lines"){
    //
    // }
    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer();
    renderer.setSize( window.innerWidth, window.innerHeight );
    // document.body.appendChild( renderer.domElement );
    this.mount.appendChild( renderer.domElement );
    var geometry = new THREE.PlaneGeometry(0.4, 0.6, 16, 16);
    var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    var cube = new THREE.Mesh( geometry, material );
    scene.add( cube );
    camera.position.z = 5;
    var animate = function () {
      requestAnimationFrame( animate );
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;
      renderer.render( scene, camera );
    };
    animate();
  }

  render() {
    return <div ref={ref => (this.mount = ref)} />
  }
}

export default ThreeView;
