import React from 'react';
import './ControlsOverlay.css'


class ControlsOverlay extends React.Component {
  render() {
    if(this.props.controls === "3D"){
      return <div id="controls"><i>Left-click: rotate</i> | <i>Mouse-wheel: zoom</i> | <i>Right-click: pan</i> | <i>Click dot: display image</i></div>
    } else {
      return <div id="controls">Left-click/drag: reposition, Mouse-wheel: zoom</div>
    }
  }
}

export default ControlsOverlay;
