import React from 'react';
import imagesJson from './images-7.json';
import './GenArtView.css'
import DeckGL from '@deck.gl/react';
import {PointCloudLayer, LineLayer, PathLayer} from '@deck.gl/layers';
import {COORDINATE_SYSTEM, OrthographicView, OrbitView, LinearInterpolator} from '@deck.gl/core';

import { useParams } from "react-router-dom";

const GenArtView = props => {
  const urlParams = useParams();
  return <GenArtViewUnwrapped params={urlParams} {...props} />
}

class GenArtViewUnwrapped extends React.Component {
  constructor(props){
    super(props)

    console.log(props)
    const INITIAL_VIEW_STATE = {
      target: [1920 / 2, 1080 /2],
      rotationOrbit: 0,
      rotationX: 90,
      minZoom: 0,
      maxZoom: 3,
      zoom: 0.5
    };


    this.state = {
      viewState: INITIAL_VIEW_STATE,
      view: new OrthographicView(),
      layers: [],
      maxHeight: 1080,
      maxWidth: 1920
    }
  }

  drawLines(){
    const XSTEP = 15;
    const YSTEP = 12;
    const VARIANCE_FACTOR = 8;

    const lines = [];
    for(let i = YSTEP; i <= this.state.maxHeight - YSTEP; i += YSTEP){
      let line = {};
      line.path = [];
      for(let j = XSTEP; j <= this.state.maxWidth; j += XSTEP){
        var sizeRand = Math.floor(Math.random() * VARIANCE_FACTOR) - VARIANCE_FACTOR/2;
        let point;
        if(lines.length == 0){
          point = [j, i + sizeRand];
        } else if(j <= this.state.maxWidth - XSTEP){
          point = [j, YSTEP + lines[lines.length - 1].path[j / XSTEP - 1][1] + sizeRand];
        } else {
          point = [j, i + sizeRand];
        }
        line.path.push(point);
        line.color = [35, 125, 35];
      }
      lines.push(line);
    }

    const path = new PathLayer({
      data: lines,
      getPath: d => d.path,
      getColor: d => d.color
    })

    this.setState({layers: [path]})
  }

  drawMath(){

    const data = [];
    // for(let deg = 0; deg <= 360; deg += 1){
    //   for(let ring = 1; ring <= 100; ring += 3){
    //     let point = {}
    //     let x = ring * Math.cos(Math.PI / 180 * deg)
    //     let y = ring * Math.sin(Math.PI / 180 * deg)
    //     let z = 2* Math.sin(x * y)
    //     point.position = [x, y, z];
    //     point.color = [35, 125, 35];
    //     data.push(point)
    //   }
    // }

    for(let x = -500; x <= 500; x += 5){
      for(let y = -500; y <= 500; y += 5){
        let point = {}
        let z = 10* Math.sin(0.5 * x * y)
        point.position = [x, y, z];
        point.color = [35, 125, 35];
        data.push(point)
      }
    }
    console.log(data)

    const path = new PointCloudLayer({
      data: data,
      getPosition: d => d.position,
      getColor: d => d.color,
      pointSize: 2
    })

    this.setState({
      layers: [path],
      viewState: {
        target: [0, 0, 0],
        rotationOrbit: 0,
        rotationX: 90,
        minZoom: 0,
        maxZoom: 3,
        zoom: 1
      },
      view: new OrbitView()
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.image != this.props.image){
      console.log("Props updated")
    }
  }

  componentDidMount(){
    const id = this.props.params.id;
    if(id == "lines"){
      this.drawLines();
    } else if (id == "math") {
      console.log("Math");
      this.drawMath();
    }
  }

  render() {
    return <div className="deck">
      <DeckGL
        views={this.state.view}
        viewState={this.state.viewState}
        controller={true}
        onViewStateChange={v => this.setState({viewState: v.viewState, rotationX: v.rotationX})}
        layers={this.state.layers}
      />
    </div>
  }
}

export default GenArtView;
