import React, {useState, useEffect} from 'react';
import ImageListView from './ImageListView.js';
import PhotoExplorer from './PhotoExplorer.js';
import GenArtView from './GenArtView.js';
import ThreeView from './ThreeView.js';
import FamilyTreeView from './FamilyTreeView.js';
import Walkers from './Walkers.js';
import Register from './Register.js';
import Reset from './Reset.js';
import Login from './Login.js';
import NavigationView from './NavigationView.js';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BeerExplorer from './BeerExplorer.js';
var convert = require('color-convert');


class App extends React.Component {
  constructor(props){
    super(props)
    this.updateUser = this.updateUser.bind(this);
    this.state = {
      user: null
    }
  }

  componentDidMount(){
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }

  updateUser(user) {
    console.log("Updating user")
    console.log(user);
    this.setState({user: user});
  }

  render() {
    return <div id="app">
          <NavigationView />
          <Router><Routes>
            <Route path="/login" element={<Login updateUser={this.updateUser} />} />
            <Route path="/photography" element={<ImageListView />} />
            <Route path="/projects/family" element={<FamilyTreeView user={this.state.user} />} />
            <Route path="/beer" element={<BeerExplorer />} />
            <Route path="/three" element={<ThreeView />} />
            <Route path="/generative/walkers" element={<Walkers />} />
            <Route path="/generative/:id" element={<GenArtView />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route path="/" element={<PhotoExplorer />} />
          </Routes></Router></div>;
  }
}

export default App;
